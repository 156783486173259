import React, { useState } from "react"
import styled from "styled-components/macro"
import { TextField, Button, Typography } from "@material-ui/core"
import { useKontaktContext } from "./machine"
import { send } from "./send"
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: 12px;
  }
`

export const Kontaktskjema = ({ formName }) => {
  const [sent, setSent] = useState(false)
  const [
    { email, nettside, nettsideIsInvalid, emailIsInvalid },
    dispatch,
  ] = useKontaktContext()
  const formIsValid = !nettsideIsInvalid && !emailIsInvalid && email && nettside

  const dispatchChange = ({ target }) => {
    dispatch({ type: "value_change", name: target.name, value: target.value })
  }
  const dispatchBlur = ({ target }) => {
    dispatch({ type: "blur", name: target.name, value: target.value })
  }

  const sendMelding = () => {
    if (formIsValid) {
      send({ nettside, email }).then(() => {
        setSent(true)
      })
    }
  }

  return (
    <Form name={formName} data-netlify="true">
      <input type="hidden" name="form-name" value={formName} />
      <TextField
        value={nettside}
        onChange={dispatchChange}
        onBlur={dispatchBlur}
        error={nettsideIsInvalid}
        name="nettside"
        id="Nettside"
        placeholder="Din nettside/url"
        label="Nettside"
        helperText={nettsideIsInvalid && "Fyll inn ditt nettside"}
      />
      <TextField
        value={email}
        onChange={dispatchChange}
        onBlur={dispatchBlur}
        error={emailIsInvalid}
        name="email"
        id="Email"
        placeholder="din@adresse.com"
        label="Email"
        helperText={emailIsInvalid && "Ugyldig email adresse"}
      />
      {sent ? (
        <Typography>
          <span aria-label="Ok" role="img">
            👍
          </span>{" "}
          Melding sendt
        </Typography>
      ) : (
        <Button
          type="button"
          onClick={sendMelding}
          variant="contained"
          color="primary"
        >
          Send forespørsel
        </Button>
      )}
    </Form>
  )
}
