import React, { useContext, useReducer } from "react"

const initialState = {
  navn: "",
  email: "",
  meldingstekst: "",
  navnIsInvalid: false,
  emailIsInvalid: false,
  meldingstekstIsInvalid: false,
}
const emailRegex = /\S+@\S+\.\S+/

const events = {
  TextChange: "TextChange",
  EmailBlur: "EmailBlur",
  EmailChange: "EmailChange",
}

const validEmail = email => emailRegex.test(email)
const validNettside = (name = "") => name.length >= 3

const validate = (name, value) => {
  switch (name) {
    case "email":
      return !validEmail(value)
    case "nettside":
      return !validNettside(value)

    default:
      throw new Error()
  }
}

function reducer(state, { type, value, name }) {
  switch (type) {
    case events.EmailBlur:
      return { ...state, emailIsInvalid: !validEmail(value) }
    case "value_change":
      return { ...state, [name.toLowerCase()]: value }
    case "blur": {
      return {
        ...state,
        [`${name.toLowerCase()}IsInvalid`]: validate(name.toLowerCase(), value),
      }
    }

    default:
      throw new Error()
  }
}

export const KontaktskjemaContext = React.createContext()

export const useKontaktContext = () => {
  return useContext(KontaktskjemaContext)
}

export const KontaktskjemaProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <KontaktskjemaContext.Provider value={[{ ...state }, dispatch]}>
      {children}
    </KontaktskjemaContext.Provider>
  )
}
